import * as client_hooks from '../../../src/hooks.client.js';


export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16')
];

export const server_loads = [];

export const dictionary = {
		"/": [7],
		"/[locale=locale]/(login)": [16,[2,6]],
		"/[locale=locale]/(app)/(secure)/allowance": [8,[2,3,4]],
		"/[locale=locale]/(app)/(secure)/block": [9,[2,3,4]],
		"/[locale=locale]/(app)/(secure)/history": [10,[2,3,4,5]],
		"/[locale=locale]/(app)/(secure)/history/[uuid]": [11,[2,3,4,5]],
		"/[locale=locale]/(app)/(secure)/main": [12,[2,3,4]],
		"/[locale=locale]/(app)/(secure)/wallet/deposit": [14,[2,3,4]],
		"/[locale=locale]/(app)/(secure)/wallet/[[depositStatus]]": [13,[2,3,4]],
		"/[locale=locale]/(app)/welcome/[uuid]": [15,[2,3]]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),

	reroute: (() => {})
};

export { default as root } from '../root.svelte';